<template>
    <div>
        <div v-for="(link, index) in filteredGeneral" :key="index" :class="{'mt-2': index}">
            <router-link :to="{name: link.path.name}" custom v-slot="{ navigate }">
                <span @click="navigate" @keypress.enter="navigate" role="link" class="text-link text-capitalize">
                    {{$t("general", {en: link.title.en, th: link.title.th})}}
                </span>
            </router-link>
        </div>
    </div>
</template>
<style scoped>
.text-link {
    cursor: pointer;
    text-decoration: none;
    color: #333333;
    transition: .25s;
}
.text-link:hover {
    color: #1F4591
}
</style>
<i18n>
{
    "en": {
        "general": "{en}"
    },
    "th": {
        "general": "{th}"
    }
}
</i18n>
<script>
import navigations from '@/assets/json/navigations.json'
export default {
    data() {
        return {
            excludedItems: ['Homepage', 'Services', 'AboutUs']
        }
    },
    computed: {
        filteredGeneral() {
            // Path names to exclude from footer
            let excludes = this.excludedItems
            let menus = navigations.general
            let filteredMenus = menus.filter(menu => !excludes.includes(menu.pathName))
            return filteredMenus
        }
    }
}
</script>