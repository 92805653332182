<template>
    <div>
        <div class="d-none d-lg-flex">
            <template v-if="userLoaded">
                <button type="button" id="profileOption" data-bs-toggle="dropdown" aria-expanded="false" class="btn-profile d-flex align-items-center fw-light p-2 rounded-pill">
                    <i class="fas fa-user-circle fa-lg me-2"></i>
                    <span>{{displayName}}</span>
                </button>
                <div aria-labelledby="profileOption" class="dropdown-menu dropdown-menu-end shadow cursor-arrow m-0">
                    <span @click="doMethod(menu.onClick)" v-for="(menu, index) in organizedMenus" :key="index" class="dropdown-item d-flex align-items-center fw-light py-2">
                        <i class="me-2 text-darkgrey" :class="menu.icon"></i>
                        <span>{{$t("menu", {en: menu.title.en, th: menu.title.th})}}</span>
                    </span>
                </div>
            </template>
        </div>
        <div class="d-block d-lg-none">
            <template v-if="userLoaded">
                <div role="button" id="profileOption" data-bs-toggle="collapse" data-bs-target="#profileNavigation" aria-controls="profileNavigation" aria-expanded="false" aria-label="Toggle Navigation" class="text-darkblue d-flex align-items-center fw-light px-4 py-3">
                    <span>{{displayName}}</span>
                    <i class="fas fa-chevron-down ms-auto"></i>
                </div>
                <div id="profileNavigation" class="collapse py-2 sub-menu">
                    <div @click="doMethod(menu.onClick)" v-for="(menu, index) in organizedMenus" :key="index" class="fw-light px-4">
                        <div :class="{'border-top': index}" class="py-3">
                            <i class="me-2 text-darkgrey" :class="menu.icon"></i>
                            <span>{{$t("menu", {en: menu.title.en, th: menu.title.th})}}</span>
                        </div>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>
<style scoped>
.btn-profile {
    border: 0;
    background: inherit;
    color: var(--navy);
    transition: .4s;
}
.btn-profile:hover,
.btn-profile:focus {
    outline: none;
    color: var(--darkBlue);
}
.dropdown-menu {
    min-width: 12em;
}
.sub-menu {
    box-shadow: inset 0px 5px 5px #00000010, inset 0px -5px 5px #00000010;
}
</style>
<i18n>
{
    "en": {
        "menu": "{en}"
    },
    "th": {
        "menu": "{th}"
    }
}
</i18n>
<script>
import firebase from 'firebase/app'
import 'firebase/firestore'
export default {
    data() {
        return {
            user: undefined,
            menus: [
                // {
                //     title: {
                //         en: "Profile",
                //         th: "บัญชีผู้ใช้"
                //     },
                //     icon: "fas fa-address-card",
                //     onClick: "toProfile",
                // },
                {
                    title: {
                        en: "Management",
                        th: "การจัดการ"
                    },
                    icon: "fas fa-user-shield",
                    onClick: "toAdmin",
                },
                {
                    title: {
                        en: "Sign Out",
                        th: "ออกจากระบบ"
                    },
                    icon: "fas fa-sign-out-alt",
                    onClick: "_signOut",
                }
            ]
        }
    },
    computed: {
        displayName() {
            const firstname = this.user.firstname
            const lastname = this.user.lastname
            return `${firstname} ${lastname}`
        },
        organizedMenus() {
            return this.menus
        },
        userLoaded() {
            return (this.user)
        }
    },
    methods: {
        async getUserDocument() {
            const currentUser = await this._getUser()
            const users = firebase.firestore().collection('users')

            if (currentUser) {
                const userDocument = await users.doc(currentUser.uid).get()
                this.user = userDocument.data()
            }
        },
        doMethod(method) {
            switch(method) {
                case 'toProfile':
                    this.$router.push({name: 'Profile'})
                    break
                case 'toAdmin':
                    this.$router.push({name: 'AdminDashboard'})
                    break
                case '_signOut':
                    this._signOut()
                    break
            }
        }
    },
    created() {
        this.getUserDocument()
    }
}
</script>