<template>
    <div class="panel">
        <Navbar />
        <router-view />
        <Footer />
    </div>
</template>
<style scoped>
.panel {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}
</style>
<script>
import Navbar from './components/Navbar/Navbar.vue'
import Footer from './components/Footer/Footer.vue'

export default {
    components: {
        Navbar,
        Footer
    }
}
</script>