<template>
    <div class="mt-auto">
        <div class="useful-links">
            <div class="container py-5">
                <div class="row">
                    <div class="col small d-none d-lg-block">
                        <General />
                    </div>
                    <div class="col d-none d-lg-block"></div>
                    <div class="col">
                        <Information />
                    </div>
                </div>
            </div>
        </div>
        <div class="bg-darkblue">
            <div class="container small py-2 text-center text-lg-start">
                &copy; 2021 JLegal Thailand Co.
            </div>
        </div>
    </div>
</template>
<style scoped>
.useful-links {
    background: #00000007
}
</style>
<script>
import General from './General.vue'
import Information from './Information.vue'
export default {
    components: {
        General,
        Information
    }
}
</script>