<template>
    <div>
        <router-link :to="{name: 'SignIn'}" custom v-slot="{ navigate }">
            <div @click="navigate" @keypress.enter="navigate" role="link" class="d-none d-lg-block btn-sign-in rounded small px-3 py-1">
                {{$t("btnSignIn")}}
            </div>
            <div @click="navigate" @keypress.enter="navigate" role="link" class="d-block d-lg-none text-center py-3 text-blue">
                {{$t("btnSignIn")}}
            </div>
        </router-link>
    </div>
</template>
<i18n>
{
    "en": {
        "btnSignIn": "Sign In"
    },
    "th": {
        "btnSignIn": "เข้าสู่ระบบ"
    }
}
</i18n>
<style scoped>
.btn-sign-in {
    background: #2B6AD0;
    color: white;
    cursor: pointer;
    transition: .25s;
}
.btn-sign-in:hover {
    background: #1F4591;
}
</style>