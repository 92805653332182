<template>
    <div class="shadow no-highlight">
        <div class="container d-flex align-items-center">
            <SiteLogo />
            <div class="d-none d-lg-flex flex-grow-1 justify-content-end my-auto ms-5">
                <div v-for="(menu, index) in menus" :key="index">
                    <div v-if="menu.subNavs" class="dropdown d-flex">
                        <a :id="menu.id" role="button" data-bs-toggle="dropdown" aria-expanded="false" :class="{'menu-active': isActive(menu.path.url)}" class="dropdown-toggle menu px-4 text-center text-capitalize">
                            {{$t("menu", {en: menu.title.en, th: menu.title.th})}}
                        </a>
                        <div :aria-labelledby="menu.id" class="dropdown-menu dropdown-menu-end shadow cursor-arrow m-0">
                            <router-link :to="{name: sub.linkName}" v-for="(sub, subIndex) in menu.subNavs" :key="subIndex" custom v-slot="{ navigate }">
                                <span @click="navigate" @keypress.enter="navigate" role="link" class="dropdown-item d-flex align-items-center fw-light py-2">
                                    <span class="small">{{ sub.title }}</span>
                                </span>
                            </router-link>
                        </div>
                    </div>
                    <router-link v-else :to="{name: menu.path.name}" custom v-slot="{ navigate }">
                        <div @click="navigate" @keypress.enter="navigate" role="link" :class="{'menu-active': isActive(menu.path.url)}" class="menu px-4 text-center text-capitalize">
                            <span>{{$t("menu", {en: menu.title.en, th: menu.title.th})}}</span>
                        </div>
                    </router-link>
                </div>
                <div class="d-inline-flex justify-content-end align-items-center ms-3">
                    <UserProfile v-if="auth" />
                    <SignInButton v-else-if="auth === false" />
                    <LanguageSelector />
                </div>
            </div>
            <div class="d-flex d-lg-none flex-grow-1 align-items-center justify-content-end my-auto navbar-light py-2">
                <LanguageSelector />
                <button type="button" data-bs-toggle="collapse" data-bs-target="#mainNavigation" aria-controls="mainNavigation" aria-expanded="false" aria-label="Toggle Navigation" class="navbar-toggler ms-2">
                    <span class="navbar-toggler-icon"></span>
                </button>
            </div>
        </div>
        <div class="collapse" id="mainNavigation">
            <div v-for="(menu, index) in menus" :key="index">
                <div v-if="menu.subNavs" class="dropdown">
                    <div :id="menu.id" role="button" data-bs-toggle="collapse" data-bs-target="#serviceNavigation" aria-controls="serviceNavigation" aria-expanded="false" aria-label="Toggle Service Navigation" :class="{'menu-active': isActive(menu.path.url)}" class="mobile navbar-toggler menu px-4 text-capitalize">
                        {{$t("menu", {en: menu.title.en, th: menu.title.th})}}
                    </div>
                    <div id="serviceNavigation" class="collapse cursor-arrow px-4 sub-menu">
                        <router-link :to="{name: sub.linkName}" v-for="(sub, subIndex) in menu.subNavs" :key="subIndex" custom v-slot="{ navigate }">
                            <div @click="navigate" @keypress.enter="navigate" role="link" :class="{'border-top': subIndex}" class="fw-light py-3">
                                <span class="small">{{ sub.title }}</span>
                            </div>
                        </router-link>
                    </div>
                </div>
                <router-link v-else :to="{name: menu.path.name}" custom v-slot="{ navigate }">
                    <div @click="navigate" @keypress.enter="navigate" role="link" :class="{'menu-active': isActive(menu.path.url)}" class="mobile menu px-4 text-capitalize">
                        <span>{{$t("menu", {en: menu.title.en, th: menu.title.th})}}</span>
                    </div>
                </router-link>
            </div>
            <div class="border-top">
                <UserProfile v-if="auth" />
                <SignInButton v-else-if="auth === false" />
            </div>
        </div>
    </div>
</template>
<style scoped>
.menu {
    font-size: 14px;
    cursor: pointer;
    padding-top: calc(0.75rem + 4px);
    padding-bottom: 0.75rem;
    border-bottom: 4px solid #FFFFFF00;
    transition: .25s;
}
.menu:hover {
    color: #2B6AD0;
    background: #68A4F110;
}
.menu-active {
    color: #2B6AD0;
    background: #68A4F125;
    border-bottom: 4px solid #1F4591;
}
.mobile.menu-active {
    color: #2B6AD0;
    background: #68A4F125;
    border-bottom: none;
}
a.dropdown-toggle {
    text-decoration: none;
    color: inherit;
}
.sub-menu {
    box-shadow: inset 0px 10px 10px #00000010, inset 0px -10px 10px #00000010;
}
</style>
<i18n>
{
    "en": {
        "menu": "{en}"
    },
    "th": {
        "menu": "{th}"
    }
}
</i18n>
<script>
import UserProfile from './UserProfile.vue'
import SignInButton from './SignInButton.vue'

import navigations from '@/assets/json/navigations.json'
import services from '@/assets/json/services.json'

export default {
    components: {
        UserProfile,
        SignInButton
    },
    data() {
        return {
            auth: undefined,
        }
    },
    computed: {
        menus() {
            let menus = navigations.general
            menus.map(menu => {
                switch (menu.id) {
                    case "services":
                        menu.subNavs = services.map(service => ({ title: service.title, linkName: service.link.name}))
                    default: return menu
                }
            })
            return menus
        }
    },
    methods: {
        isActive(pathUrl) {
            const fullPath = this.$route.path
            const firstPath = fullPath.split('/')[1]
            return (pathUrl === firstPath)
        },
        async updateAuthStatus() {
            this.auth = await this._isAuth()
        }
    },
    created() {
        this.updateAuthStatus()
    }
}
</script>