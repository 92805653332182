<template>
    <div>
         <SiteLogo />
        <div class="text-muted my-1">
            <div class="mb-2">{{$t('address')}}</div>
            <div>{{$t('tel')}}</div>
        </div>
        <div class="d-flex mt-3">
            <a :href="contact.src" :target="{_blank: contact.external}" v-for="(contact, index) in contacts" :key="index" class="circle rounded-circle" :class="{'ms-3': index}">
                <i class="icon" :class="contact.icon"></i>
            </a>
        </div>
    </div>
</template>
<style scoped>
.circle {
    position: relative;
    width: 2rem;
    height: 2rem;
    background: #092d6b;
    transition: .2s;
}
.circle:hover {
    transform: scale(1.1025);
}
.icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
}
</style>
<i18n>
{
    "en": {
        "address": "Pattanakarn Rd. Bangkok, 10250",
        "tel": "Phone 089 793 8185"
    },
    "th": {
        "address": "ถนนพัฒนาการ กรุงเทพมหานคร 10250",
        "tel": "โทร 089 793 8185"
    }
}
</i18n>
<script>
export default {
    data() {
        return {
            contacts: [
                {
                    title: "email",
                    icon: "fas fa-envelope",
                    src: "mailto:admin@jlegalthailand.com"
                },
                {
                    title: "facebook",
                    icon: "fab fa-facebook-f",
                    src: "https://www.facebook.com/JNPAccounting/",
                    external: true
                },
            ]
        }
    }
}
</script>